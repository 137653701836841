import React from 'react'
import styles from './Footer.module.scss'

const year = new Date().getFullYear();

export default () => (
    <footer className={styles.footer}>
        <div className={`${styles.footerLink}`}>
            <a href="/privacy">プライバシーポリシー</a>
            <a href="/commerce">特定商取引法に基づく表記</a>
        </div>
        <div className={`${styles.footerBar}`}>
            {`© ${year} grid.io Inc.`}
        </div>
    </footer>
)