import React from 'react'
import styles from './Header.module.scss'

class Header extends React.Component {
    constructor() {
        super();
        this.state={
            navOpen: false
        }
    }

    handleClick() {
        let navOpen = this.state.navOpen;
        this.setState({
            navOpen: !navOpen,
        })
    }

    render() {
        return (
            <header className={styles.header}>
                <h1 className="sr-only">株式会社grid.io</h1>
                <a href="/" className={styles.logo}>
                    <img src="/assets/logo_gridio_dark.png" alt="株式会社grid.io"/>
                </a>
                <nav className={`${styles.nav} ${this.state.navOpen ? styles.isOpen : ''}`}>
                    <a href="/">Home</a>
                    <a href="/about">About</a>
                    <a href="/services">Services</a>
                    <a href="/news">News</a>
                    <div className={styles.navSmall}>
                        <a href="/privacy">プライバシーポリシー</a>
                        <a href="/commerce">特定商取引法に基づく表記</a>
                    </div>
                </nav>
                <div className={styles.button} onClick={() => this.handleClick()}>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </header>
        );
    }

}

export default Header;